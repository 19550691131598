<script setup lang="ts">
    import { useOrderInfoSidebarAction } from '@/composables/order-info-sidebar';
    import { computed } from 'vue';
    import { useDateFormatter } from '@/composables/date-format';
    import NavBarVerticalDivider from '@/components/navigation/NavBarVerticalDivider.vue';
    import { isMobile } from '@/util/breakpoints';
    import { useI18n } from 'vue-i18n';
    import { useCartQuery } from '@/composables/cart';
    import { useProvisionTypeQuery } from '@/composables/provision-type';

    const { t } = useI18n();
    const orderInfoSidebarAction = useOrderInfoSidebarAction();

    const { isRentalProvisionType } = useProvisionTypeQuery();
    const { rentalStart, rentalEnd, hasRegionSelected, currentZipCode } = useCartQuery();

    const translationKeySalesRentalRegion = computed(() =>
        isRentalProvisionType.value ? 'REGION.SELECT_RENTAL_REGION' : 'REGION.SELECT_SALES_REGION'
    );
    const { dateFormatter } = useDateFormatter();

    const rentalStartFormatted = computed(() =>
        rentalStart.value != null ? dateFormatter.formatDate(rentalStart.value, 'short') : ''
    );
    const rentalEndFormatted = computed(() =>
        rentalEnd.value != null ? dateFormatter.formatDate(rentalEnd.value, 'short') : ''
    );
</script>

<template>
    <div class="settings-selector-content">
        <div
            class="selection-button"
            data-testid="order-info-sidebar-button"
            @click="orderInfoSidebarAction.setIsVisible(true)"
        >
            <div class="selection-button-text">
                <div
                    :class="
                        hasRegionSelected ? 'text-md-regular-line-height-auto' : 'text-base-semibold-line-height-auto'
                    "
                >
                    {{ t(translationKeySalesRentalRegion) }}
                </div>
                <div
                    v-if="hasRegionSelected"
                    class="text-base-semibold-line-height-auto"
                    data-testid="catalog-settings-current-zip-code"
                >
                    {{ currentZipCode }}
                </div>
            </div>
            <div v-if="!isMobile"><span class="pi pi-map-marker"></span></div>
        </div>
        <NavBarVerticalDivider v-if="isRentalProvisionType && !isMobile" />
        <div v-if="isRentalProvisionType" class="selection-button" @click="orderInfoSidebarAction.setIsVisible(true)">
            <div class="selection-button-text">
                <div
                    :class="
                        rentalStart != null && rentalEnd != null
                            ? 'text-md-regular-line-height-auto'
                            : 'text-base-semibold-line-height-auto'
                    "
                >
                    {{ t('RENTAL.PERIOD') }}
                </div>
                <div v-if="rentalStart != null && rentalEnd != null" class="text-base-semibold-line-height-auto">
                    {{ rentalStartFormatted }} - {{ rentalEndFormatted }}
                </div>
            </div>
            <div v-if="!isMobile"><span class="pi pi-calendar"></span></div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .settings-selector-content {
        display: flex;
        flex-direction: column;
    }

    .selection-button {
        cursor: pointer;
        display: flex;
        gap: main.$spacing-3;
        vertical-align: middle;
        align-items: center;
        padding: main.$spacing-4 main.$spacing-3 main.$spacing-4 0;
        border-radius: 3px;
        transition: background-color 0.2s ease; // same as primevue button
    }

    .selection-button-text {
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    @include main.for-breakpoint-lg {
        .settings-selector-content {
            flex-direction: row;
        }

        .selection-button {
            padding: main.$spacing-3 main.$spacing-4;

            &:hover {
                background: main.$primaryDarkColor;
            }
        }

        .selection-button-text {
            align-items: end;
        }
    }
</style>
