<script setup lang="ts"></script>

<template>
    <div class="vertical-divider" />
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .vertical-divider {
        display: flex;
        vertical-align: middle;
        align-items: center;
        padding: 0 main.$spacing-5;

        &::after {
            content: '';
            width: 1px;
            height: 50%;
            background-color: main.$vt-c-white;
            opacity: 50%;
        }
    }
</style>
