<script setup lang="ts">
    import { useCartQuery } from '@/composables/cart';
    import router from '@/router';
    import { getComputedCartUrlByProvisionType } from '@/util/router';
    import Button from 'primevue/button';
    import { useI18n } from 'vue-i18n';

    withDefaults(
        defineProps<{
            inMobileNav?: boolean;
        }>(),
        {
            inMobileNav: false,
        }
    );

    const cartQuery = useCartQuery();
    const { t } = useI18n();

    const cartUrl = getComputedCartUrlByProvisionType();
</script>

<template>
    <Button
        v-if="inMobileNav && cartUrl != null"
        :label="t('CART.CART')"
        icon="pi pi-shopping-cart"
        link
        class="mobile-button text-base-semibold-line-height-auto"
        @click="router.push({ name: cartUrl })"
    />
    <Button v-else-if="cartUrl != null" data-testid="navbar-cart-button" @click="router.push({ name: cartUrl })">
        <i
            v-if="cartQuery.lineItemsQuantityCount.value > 0"
            v-badge.warning="cartQuery.lineItemsQuantityCount.value"
            class="pi pi-shopping-cart"
        />
        <i v-else class="pi pi-shopping-cart" />
    </Button>
</template>

<style scoped lang="scss">
    @use 'src/styling/main' as *;

    :deep(.p-badge.p-badge-warning) {
        padding: 0;
        border-radius: 50%;
        min-width: $spacing-5;
        height: $spacing-5;
    }

    .mobile-button {
        color: $color-text;
        text-align: left;
        padding-left: 0;
    }
</style>
